import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

import * as ReactDOMClient from "react-dom/client"
import CssBaseline from "@mui/material/CssBaseline"

//import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

const root = ReactDOMClient.createRoot(document.getElementById("root")!)

root.render(
  <React.Fragment>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </React.Fragment>
)
