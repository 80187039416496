import Gallery from "./Gallery"

import FolderView from "./FolderView"
import { Photo } from "react-photo-album"

import { convertKeysToLowerCase } from "./utils"

export interface FolderViewProps {
  name: string
  url: string
  urlPrev?: string
  urlNext?: string
  photos: Photo[]
}
export interface FolderItem {
  name: string
  url: string
}

export interface FolderItemProps {
  fileJson: JsonValue
  // name: string;
  // items: FolderItem[];
  // subFolders: FolderItemProps[];
}

export interface PhotoAlbumProps {
  currentUrl?: string
  folderItems: FolderItemProps[]
}

export type JsonValue =
  | string
  | number
  | boolean
  | null
  | JsonObject
  | JsonArray
  | any
export interface JsonObject {
  [key: string]: JsonValue
}
export interface JsonArray extends Array<JsonValue> {}

export interface FileJson extends Object {}

/** Route url */
export const ROOT = "photoalbum"

/**
 * Media folder path inside public_html
 */
export const PATH = "album"

export { Gallery, FolderView, convertKeysToLowerCase }
