import { JsonArray, JsonObject, JsonValue } from "."

function convertKeysToLowerCase(obj: JsonValue): JsonValue {
  if (typeof obj !== "object" || obj === null) {
    return obj // Base case: return non-object values as is
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToLowerCase(item)) as JsonArray
  }

  // Handle objects
  const convertedObject: JsonObject = {}
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const lowercasedKey = key.toLowerCase()
      const value = obj[key]

      // Recursively convert nested objects
      convertedObject[lowercasedKey] = convertKeysToLowerCase(
        value
      ) as JsonValue
    }
  }

  return convertedObject
}

function hasOwnPropertyCaseInsensitive(
  obj: Record<string, any>,
  prop: string
): boolean {
  const propLowerCase = prop.toLowerCase()

  return Object.keys(obj).some((key) => key.toLowerCase() === propLowerCase)
}

function getPropertyValueCaseInsensitive(
  obj: Record<string, any>,
  prop: string
): any | null {
  const propLowerCase = prop.toLowerCase()

  const foundKey = Object.keys(obj).find(
    (key) => key.toLowerCase() === propLowerCase
  )

  return foundKey ? foundKey : null
}

export {
  convertKeysToLowerCase,
  hasOwnPropertyCaseInsensitive,
  getPropertyValueCaseInsensitive,
}
