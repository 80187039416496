import React, { ReactNode, memo, useEffect, useState } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import {
  FolderView,
  JsonArray,
  JsonObject,
  JsonValue,
  PATH,
  ROOT,
} from "../components"
import DefaultPage from "../components/DefaultPage"

interface RouterProviderProps {
  // Define your router provider props here
  children?: ReactNode
  fileJson: JsonObject
}

function getRoutes(
  jsonObject: JsonValue,
  currentKey: string,
  result: string[]
): string[] {
  //console.log(jsonObject && Object.keys(jsonObject))
  if (typeof jsonObject !== "object" || jsonObject === null) {
    return [] // Base case: return empty array
  }

  for (const key in jsonObject) {
    if (jsonObject.hasOwnProperty(key)) {
      const value = jsonObject[key] as JsonValue
      if (key !== "__files__" && key !== "cgi-bin") {
        result.concat(getRoutes(value, currentKey + "/" + key, result))
      }
    } else result.push(currentKey + "/" + key)

    // Use type assertion here
    const keyAsProperty = key as keyof JsonObject | keyof JsonArray

    if (jsonObject.hasOwnProperty(keyAsProperty)) {
      // Handle both objects and arrays when appending to the result
      //const separator = Array.isArray(jsonObject) ? "[" + key + "]" : key
      if (key !== "__files__" && key !== "cgi-bin") {
        result.push(currentKey + "/" + key)
      }
    }
  }

  return result
}

const RouterProvider: React.FC<RouterProviderProps> = ({
  children,
  fileJson,
}) => {
  const defaultRoutes: ReactNode[] = [
    <Route path="/" element={<DefaultPage />} key="root" />,
    <Route
      path={`/${ROOT}/*`}
      element={<FolderView fileJson={fileJson} />}
      key={ROOT}
    />,
  ]
  const [routeList, setRouteList] = useState<ReactNode>(defaultRoutes)

  useEffect(() => {
    //console.log("ROUTES: ")
    //console.log(fileJson ? JSON.stringify(fileJson) : "")
    let routes: string[] = []
    if (fileJson) {
      const obj: JsonValue = {}
      routes = getRoutes(obj, PATH, [])
      console.log(`Router routes: ${routes}`)

      const tmp = routes.map((r, i = 0) => (
        <Route
          path={`/${PATH}` + r}
          element={<FolderView fileJson={fileJson} />}
          key={i}
        />
      ))
      const routerObjs: ReactNode[] = [...defaultRoutes, ...tmp]
      setRouteList(routerObjs)
      //console.log("route list: " + JSON.stringify(routerObjs))
    }
  }, [fileJson])

  return (
    <BrowserRouter>
      <Routes>
        {routeList}
        {children ?? []}
      </Routes>
    </BrowserRouter>
  )
}

export default memo(RouterProvider)
