import React, { useEffect, useState } from "react"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import { FileJson, FolderItemProps, JsonObject } from "./components"
import RouterProvider from "./router/RouterProvider"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const processData = (data: FileJson) => {
  const result: FolderItemProps[] = []
  return result
}

function App() {
  const [jsonData, setJsonData] = useState<JsonObject>(
    null as unknown as JsonObject
  )
  //const [folderItems, setFolderItems] = useState<FolderItemProps[]>([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/data.json")
        const data = await response.json()
        //setJsonData(convertKeysToLowerCase(data))
        setJsonData(data)

        //console.log("Set json data" + JSON.stringify(data))
      } catch (error) {
        console.error("Error fetching JSON data:", error)
      }
    }
    //alert("Fetch data")
    fetchData()
  }, []) // Empty dependency array ensures the effect runs once on mount

  return <RouterProvider fileJson={jsonData} />
}

export default App
