import { Box, Container, Divider, Link, Typography } from "@mui/material"
import React, { Fragment } from "react"
const DefaultPage = () => {
  // Render the HTML content directly
  return (
    <Container sx={{ p: 2, textAlign: "center" }}>
      <Box pb={2} />
      <Divider />
      <Box pt={2} pb={2} />

      {/* <Typography variant="h3">Welcome to: </Typography> */}
      <Typography align="center" variant="h4">
        Netwind Corp.
      </Typography>
      <Typography align="center">
        IT Consulting, systems administration, operating systems, networks.
      </Typography>

      <Box pt={2} pb={2} />
      <Divider />
      <Box pt={2} pb={2} alignItems={"center"} alignContent={"center"}>
        <Typography>Also see out site:</Typography>
        <Link href="http://www.worldautotravel.com">
          http://www.worldautotravel.com
        </Link>
      </Box>
    </Container>
  )
}

export default DefaultPage
