import React, { Fragment, memo, useEffect, useState } from "react"
import "yet-another-react-lightbox/styles.css"

// import optional lightbox plugins

import "yet-another-react-lightbox/plugins/thumbnails.css"
import { Container, Typography } from "@mui/material"
import { FolderItemProps, JsonValue, PATH } from "."
import { useLocation } from "react-router"
import ReactResponsiveGallery from "react-responsive-gallery"
import { MediaElementProps } from "react-responsive-gallery/dist/declarations/src/components/Gallery/Gallery.types"
import { getPropertyValueCaseInsensitive } from "./utils"
import { Link } from "react-router-dom"

interface LinkObj {
  name: string
  path: string
}

function getFolderUrl(key: string, pathname: string) {
  if (pathname.endsWith("/")) return pathname + key
  return pathname + "/" + key
}

const excludeFolders = [".", "cgi-bin", "resources", ".well-known"]

const FolderView: React.FC<FolderItemProps> = (props) => {
  const fileJson: JsonValue = props.fileJson

  const [folderName, setFolderName] = useState<string>("Album")
  const [media, setMedia] = useState<MediaElementProps[]>([])
  const [subFolders, setSubFolders] = useState<LinkObj[]>([])

  //if (fileJson) console.log(`fileJson: ${Object.keys(fileJson)}`)
  const location = useLocation()
  useEffect(() => {
    const tokens: string[] = location.pathname
      .toLowerCase()
      .split("/")
      .filter((token) => token !== "")
      .map((token) => token.toLowerCase())

    if (fileJson) {
      const keys: string[] = Object.keys(fileJson)
      console.debug(`tokens: ${JSON.stringify(tokens)}`)
      const propKeys: string[] = []

      if (keys.length > 0 && keys && keys.length > 0) {
        let val: JsonValue = fileJson

        for (let i = 1; i < tokens.length; i++) {
          try {
            if (val) {
              const propKey = getPropertyValueCaseInsensitive(val, tokens[i])
              if (propKey) {
                val = val[propKey]
                // console.log(`token[${i}]: ${tokens[i]}, propKey: ${propKey}`)
                // console.log(
                //   `val keys ${i}: ${val && JSON.stringify(Object.keys(val))}`
                // )
                propKeys.push(propKey)
              }
            }
          } catch (e) {
            console.error("My error: " + e)
          }
        }
        if (val) {
          // console.log("&&& ***")
          // console.log(`val 2: ${JSON.stringify(val)}`)
          // console.log(`keys: ${val && JSON.stringify(Object.keys(val))}`)
          const subFolders = []
          for (const key in val) {
            //console.log(`key: ${key}`)
            if (val.hasOwnProperty(key)) {
              //console.log(`##### key: ${key}`)
              const path = `/${PATH}/` + propKeys.join("/") + "/"
              if (key === "__files__") {
                const files = val["__files__"]
                //console.log("Files:" + JSON.stringify(val["__files__"]))
                tokens.shift()

                const media = files.map((x: string) => ({
                  src: path + x,
                  name: x,
                  key: x,
                }))

                setMedia([
                  ...media,
                  // {
                  //   src: "/Nora/20151107/D71_8028-Nora.jpg",
                  //   name: "test",
                  //   key: "test",
                  // },
                ])
                console.debug("media:" + JSON.stringify(media))
              } else {
                setMedia([])
                if (!excludeFolders.includes(key))
                  subFolders.push({
                    path: getFolderUrl(key, location.pathname),
                    name: key,
                  })
              }
            }
            if (propKeys.length > 0) {
              setFolderName(propKeys[propKeys.length - 1])
            }
            setSubFolders(
              subFolders.slice().sort((a, b) => a.name.localeCompare(b.name))
            )
          }
        }
      }
    }
  }, [fileJson, location])

  // useEffect(() => {
  //   console.log(`Subfolders: ${JSON.stringify(folderName)}`)
  // }, [folderName])

  return (
    <Container sx={{ p: 2, border: "1 solid red" }}>
      <Typography
        variant="h1"
        sx={{ textTransform: "capitalize" }}
      >{`${folderName}`}</Typography>
      <Container>
        {subFolders.map((folderObj) => (
          <Fragment key={folderObj.name}>
            <Link to={folderObj.path}>
              <Typography sx={{ textTransform: "capitalize" }}>
                {folderObj.name}
              </Typography>
            </Link>
          </Fragment>
        ))}

        <ReactResponsiveGallery useLightBox media={media} />
      </Container>
    </Container>
  )
}

export default memo(FolderView)
